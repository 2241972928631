<template>
  <div>
    <el-row style="margin-top: 15px;padding-left: 10px">
      <el-table
          :row-style="{height:36+'px'}"
          :cell-style="{padding:5+'px'}"
          :data="table.stateList"
          border
          max-height="750"
          :header-cell-style="{background:'#fafafc',color:'#606266',padding: '7px 0'}"
          :default-sort="{prop: 'id', order: 'ascending'}"
          style="width: 99%">
        <el-table-column prop="stateName" label="状态名称" align="center"></el-table-column>
        <el-table-column prop="stateType" label="状态类型" align="center"></el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top: 5px">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="this.table.limit"
          layout="prev, pager, next,total, jumper, sizes"
          :total="table.total">
      </el-pagination>
    </el-row>
  </div>
</template>

<script>

export default {
  name: "state-list",
  data() {
    return {
      tenantCrop: localStorage.getItem("tenantCrop"),
      table: {
        stateList: [],
        page: 1,
        limit: 100,
        total: 0,
      },
      dialog: {
        shopAddState: false,
        shopEditState: false,
        shop: {},
      }

    }
  },
  created() {
    this.queryStateList();
  },
  methods: {
    search() {
      this.table.page = 1;
      this.queryStateList();
    },
    //查询客户列表
    queryStateList: function () {
      this.$axios({
        method: "GET",
        url: "/state/stateList",
        params: {
          page: this.table.page,
          limit: this.table.limit,
          tenantCrop: this.tenantCrop,
        }
      }).then(response => {
        console.log(response)
        this.table.total = response.data.data.total;
        this.table.stateList = response.data.data.list;
      })
    },
    //更改每页长度
    handleSizeChange: function (val) {
      this.table.limit = val;
      this.queryStateList();
    },
    handleCurrentChange(val) {
      this.table.page = val
      this.queryStateList()
    },
  }
}
</script>

<style scoped>

</style>